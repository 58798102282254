import { createSelector } from '@reduxjs/toolkit';
import { getMediaMultiLang, getTextMultiLang } from '@utils/helper';
import { selectDomain } from './mainSelectors';
import { I_Post } from '../types';

/**
 * SECTION: DETAIL PROJECT SELECTORS
 */

export const selectLanguageProjectSelected = createSelector(
  [selectDomain],
  state => state.languageProjectSelected || undefined,
);

const selectDefaultLanguageProject = createSelector(
  [selectDomain],
  state => state.project?.languages?.find(item => item.default),
);

export const selectDetailProject = createSelector(
  [selectDomain, selectLanguageProjectSelected, selectDefaultLanguageProject],
  (state, languageProjectSelected, defaultLanguageProject) =>
    state.project
      ? {
          ...state.project,
          title:
            getTextMultiLang(
              state.project?.title,
              state.project?.keyTranslations,
              languageProjectSelected,
              defaultLanguageProject,
            ) || '',
          subTitle:
            getTextMultiLang(
              state.project?.subTitle,
              state.project?.keyTranslations,
              languageProjectSelected,
              defaultLanguageProject,
            ) || '',
          background_sound_multi_lang: getMediaMultiLang(
            state.project?.background_sound_multi_lang as unknown as string,
            state.project?.keyTranslations,
            languageProjectSelected,
            defaultLanguageProject,
          ),
        }
      : undefined,
);

export const selectConfigAutoRotate = createSelector(
  [selectDomain],
  state => state.configAutoRotate || undefined,
);

export const selectConfigAutoChangeScene = createSelector(
  [selectDomain],
  state => state.configAutoChangeScene || undefined,
);

export const selectConfigSoundProject = createSelector(
  [selectDomain],
  state => state.configSoundProject || undefined,
);

export const selectIsMatterportProject = createSelector(
  [selectDetailProject],
  project => project?.project_type === 'matterport',
);

export const selectTitleProject = createSelector(
  [
    selectDetailProject,
    selectLanguageProjectSelected,
    selectDefaultLanguageProject,
  ],
  (project, languageProjectSelected, defaultLanguageProject) =>
    getTextMultiLang(
      project?.title,
      project?.keyTranslations,
      languageProjectSelected,
      defaultLanguageProject,
    ) || '',
);

export const selectFirstSceneMediaProject = createSelector(
  [selectDetailProject],
  project => project?.first_scene?.media || undefined,
);

export const selectThumbnailProject = createSelector(
  [selectDetailProject],
  project => project?.thumbnail || undefined,
);

export const selectFeatureImageProject = createSelector(
  [selectDetailProject],
  project => project?.featured_image || undefined,
);

export const selectListPostUsedOnTour = createSelector(
  [
    selectDomain,
    selectDetailProject,
    selectLanguageProjectSelected,
    selectDefaultLanguageProject,
  ],
  (state, project, languageProjectSelected, defaultLanguageProject) =>
    (state.listPost || []).map(item => ({
      ...item,
      title: getTextMultiLang(
        item.title,
        project?.keyTranslations,
        languageProjectSelected,
        defaultLanguageProject,
      ),
      content: getTextMultiLang(
        item.content,
        project?.keyTranslations,
        languageProjectSelected,
        defaultLanguageProject,
      ),
    })) as I_Post[],
);
